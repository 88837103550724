@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.React-modal-content {
    position: absolute;
    inset: 40px 40px 0px;
    border: none;
    background: rgb(59, 59, 59);
    overflow: auto;
    border-radius: 6px;
    outline: none;
    padding: 2rem;
    color: rgb(255, 255, 255);
    width: 80vw;
    margin-top: calc(100vh - 280px);
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 500px) {
    .React-modal-content {
        top: -200px;
    }
}

@media only screen and (min-width: 501px) and (max-width: 700px) {
    .React-modal-content {
        top: -100px;
    }
}
.React-modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
}
